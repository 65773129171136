import resume from '../assets/pdf/resume.pdf'
import photocv from '../assets/svg/about/photocv.png'

export const headerData = {
    name: 'Hamza El hilali',
    title: "Web Developer Full stack",
    desciption:"There wasn't a bird in the sky, but that was not what caught his attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. She knew what was coming and he hoped he was prepared. ",
    image: photocv,
    resumePdf: resume
}
