import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'quote management',
        projectDesc: 'This project aims to build an quote management system that consolidates all information of a company',
        tags: ['PHP', 'CSS', 'laravel', 'js', 'bootstrap'],
        code: 'https://github.com/Elhilali-hA/devis',
        demo: 'https://github.com/Elhilali-hA/devis',
        image: one
    },
    {
        id: 2,
        projectName: 'Booking',
        projectDesc: 'This project is a booking project that allows users to book rooms and choice Hotels.',
        tags: ['Js', 'React', 'Node js' , 'Mongo db'],
        code: 'https://github.com/Elhilali-hA/Mybooking',
        demo: 'https://github.com/Elhilali-hA/Mybooking',
        image: two
    },
    {
        id: 3,
        projectName: 'Gestion Users',
        projectDesc: 'systems and applications for management users.',
        tags: ['JS', 'CSS', 'Node', 'EJS'],
        code: 'https://github.com/Elhilali-hA/gestion_utilisateur',
        demo: 'https://github.com/Elhilali-hA/gestion_utilisateur',
        image: three
    },
    {
        id: 4,
        projectName: 'Rent Car',
        projectDesc: 'This project provide a service that people can rent cars.',
        tags: ['PHP MVC', 'JS', 'HTML', 'CSS'],
        code: 'https://github.com/Elhilali-hA/location',
        demo: 'https://github.com/Elhilali-hA/location',
        image: six
    },
    {
        id: 5,
        projectName: 'Restaurant App',
        projectDesc: 'A Simple Restaurant application',
        tags: ['JS', 'HTML', 'CSS', 'bootstrap'],
        code: 'https://github.com/Elhilali-hA/location',
        demo: 'https://github.com/Elhilali-hA/location',
        image: five
    },
    {
        id: 6,
        projectName: 'calculator',
        projectDesc: 'Simple calculator',
        tags: ['JS', 'HTML', 'CSS'],
        code: 'https://github.com/Elhilali-hA/calcul',
        demo: 'https://github.com/Elhilali-hA/calcul',
        image: four
    },
    {
        id: 7,
        projectName: 'TO Do List',
        projectDesc: 'To do list app',
        tags: ['Node', 'JS', 'REST API'],
        code: 'https://github.com/Elhilali-hA/To-do-List-Node-js-Native',
        demo: 'https://github.com/Elhilali-hA/To-do-List-Node-js-Native',
        image: seven
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/