export const educationData = [
    {
        id: 1,
        institution: 'Youcode',
        course: 'developer web & mobile',
        startYear: '2020',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'university Ibn tofail',
        course: 'Bachelor mechatronics',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        institution: 'BTS senior technician patent',
        course: 'electrical engineering',
        startYear: '2014',
        endYear: '2017'
    },
]