export const experienceData = [
    {
        id: 1,
        company: 'Wibday',
        jobtitle: 'Developer PHP/Laravel',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 2,
        company: 'freelancer',
        jobtitle: 'Backend Developer',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'fiverr',
        jobtitle: 'frontend Developer',
        startYear: '2019',
        
    },
]